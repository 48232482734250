import React, { useState, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useRouter } from "next/router";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import Head from "next/head";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Hidden from "@material-ui/core/Hidden";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import customStyles from "assets/jss/custom/customStyles.js";

// Sections for this page
import TeamSection from "pages-sections/LandingPage-Sections/TeamSection.js";
//components

import TypeSelect from "components/Content/TypeSelect";
import CTABlock from "components/Content/CTABlock";

import USPBlock from "components/Content/USPBlock.js";

//header image

import headerImage1 from "assets/img/header/sugardaddy-sugarbabe.jpg";
import headerImage2 from "assets/img/header/sugardaddy-finden.jpg";
import headerImage3 from "assets/img/header/sugarbabe-finden.jpg";

import mobileHeaderImage1 from "assets/img/header/sugardaddy-sugarbabe-mobile.jpg";
import mobileHeaderImage2 from "assets/img/header/sugardaddy-finden-mobile.jpg";
import mobileHeaderImage3 from "assets/img/header/sugarbabe-finden-mobile.jpg";

import sneakSearchProfiles from "assets/img/sneakSearchProfiles.jpg";

import sneakExplore from "assets/img/sneakExplore.svg";
import sneakMessenger from "assets/img/sneakMessenger.svg";
import sneakEvents from "assets/img/sneakEvents.svg";
import { Grid } from "@material-ui/core";
import Parser from "rss-parser";

//Styles

const headerImages = [
  {
    desktop: headerImage1.src,
    mobile: mobileHeaderImage1.src,
    alt: "Mann Frau",
  },
  {
    desktop: headerImage2.src,
    mobile: mobileHeaderImage2.src,
    alt: "Mann finden",
  },
  {
    desktop: headerImage3.src,
    mobile: mobileHeaderImage3.src,
    alt: "Frau finden",
  },
];

const headerImage =
  headerImages[Math.floor(Math.random() * Math.floor(headerImages.length))];

//const headerImage = headerImages[2]

const dashboardRoutes = [];

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
  rowImage: {
    backgroundImage: `url(${headerImage2.src})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
  },
});

export async function getStaticProps() {
  const parser: Parser = new Parser({
    headers: {
      Authorization: "Basic c2R1c2VyOnNkMjAyMA==",
    },
  });
  const tagCities = [
    "Berlin",
    "München",
    "Hamburg",
    "Wien",
    "Frankfurt am Main",
    "Zürich",
    "Düsseldorf",
    "Innsbruck",
    "Köln",
    "Stuttgart",
    "Hannover",
    "Graz",
    "Nürnberg",
    "Salzburg",
    "Dresden",
    "Basel",
    "Leipzig",
    "Karlsruhe",
    "Genf",
    "Bremen",
    "Bern",
    "Dortmund",
    "Linz",
  ];

  const feed = await parser.parseURL("https://www.getasugar.de/ratgeber/feed");
  const shuffledArray = feed.items.sort((a, b) => 0.5 - Math.random());

  const blogTeaser = shuffledArray.slice(0, 3).map((item) => {
    let urlMatch = item.content.match(/<img.*src="(.*?)"/i);

    return {
      title: item.title,
      imageUrl: urlMatch && urlMatch[1],
      teaser: item.contentSnippet,
      url: item.link,
    };
  });

  return {
    props: {
      blogTeaser,
      tagCities,
    },
    revalidate: 300,
  };
}

export default function LandingPage(props) {
  const classes = useStyles();
  const router = useRouter();
  const { tagCities, blogTeaser, ...rest } = props;
  const [type, setType] = useState<"0" | "1">("0");
  const video = useRef<HTMLVideoElement>();
  const playButton = useRef<HTMLDivElement>();

  let typeSelect = (
    <TypeSelect setType={setType} type={type} variant="highlighted" />
  );

  const purpleCTAContainer = (
    <div className={classNames(classes.rowOdd, classes.isWhite)}>
      <CTABlock />
    </div>
  );

  const imageCTAContainer = (
    <div
      className={classNames(
        classes.rowImage,
        classes.isWhite,
        classes.hasMarginTop10
      )}
    >
      <div className={classes.container}>
        <h2>Jetzt anmelden</h2>
      </div>
      <CTABlock />
    </div>
  );

  const headerSignup = (
    <div className={classes.container}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12} sm={8} md={5} style={{ padding: 15, marginTop: 35 }}>
          <div className={classes.signUpBox}>
            <h1 className={classes.title}>
              Erfolgreiche Männer
              <br />
              treffen attraktive Frauen
            </h1>

            <div
              className={classNames(classes.isBlack, classes.hasMarginTop10)}
            >
              {typeSelect}
            </div>
            <Button
              color="primary"
              fullWidth
              onClick={() => router.push("/register?type=" + type)}
            >
              jetzt kostenlos flirten
            </Button>

            <Hidden xsDown>
              <p
                className={classNames(
                  classes.hasTextCentered,
                  classes.hasMarginTop10
                )}
              >
                <small>
                  Schon bei getasugar?{" "}
                  <a
                    href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
                    className={classNames(classes.isPrimary, classes.isBold)}
                  >
                    » Login
                  </a>
                </small>
              </p>
            </Hidden>
            <div>
              <ul className={classes.checkmarkList}>
                <li>
                  <strong>Anonym flirten </strong>
                  <Hidden xsDown> - individuelle Namenswahl möglich</Hidden>
                </li>
                <li>
                  <strong>Diskret & sicher</strong>
                  <Hidden xsDown> - SSL verschlüsselte Kommunikation</Hidden>
                </li>
                <li>
                  <strong>Verifizierte Mitglieder</strong>
                  <Hidden xsDown>- Geprüfte Profile und Bilder</Hidden>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={7}></Grid>
      </Grid>
    </div>
  );

  const onVideoClick = (event) => {
    event.preventDefault();
    if (video.current?.paused) {
      video.current?.play();
      if (playButton.current) {
        playButton.current.style.display = "none";
      }
    } else {
      video.current?.pause();
      if (playButton.current) {
        playButton.current.style.display = "block";
      }
    }
  };

  return (
    <div>
      <Head>
        <title>
          Mann sucht Frau | getasugar - das Dating Portal
        </title>
        <meta
          name="description"
          content="Mann finden: Erfolgreiche Gentlemen treffen auf attraktive Damen | niveauvolle Dating Plattform für die, die Luxus & Lifestyle lieben"
        />
      </Head>
      <Header
        color="primary"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks adaptiveFold={true} />}
        fixed
        {...rest}
      />
      <Parallax
        responsive
        image={headerImage.desktop}
        mobileImage={headerImage.mobile}
        alt={headerImage.alt}
        style={{ paddingBottom: 20 }}
      >
        <Hidden xsDown>{headerSignup}</Hidden>
      </Parallax>

      <div className={classes.main}>
        <Hidden smUp>{headerSignup}</Hidden>
        <USPBlock />

        <div className={classNames(classes.container, classes.hasMarginTop10)}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <h2 className={classes.h2}>Was ist getasugar?</h2>
              <p className={classes.isTeaser}>
                Besondere Begegnungen, die glücklich machen ...
              </p>
            </Grid>
            <Grid item xs={12}>
              <p style={{ paddingTop: "1em" }}>
                Auf getasugar.de findest du spannende und niveauvolle
                Begegnungen, die auf absoluter Ehrlichkeit beruhen. Erfolgreiche
                und finanziell unabhängige Gentlemen treffen hier
                auf attraktive Frauen. Die Ansprüche und
                Bedürfnisse beider Partner sind von Anfang an klar definiert und
                transparent.
              </p>
            </Grid>

            <Grid item xs={12}>
              <p>
                Frauen profitieren vom Lifestyle der erfolgreichen Männer. Mit
                Stil und Lebenserfahrung heben sich diese klar von den
                männlichen Kontakten klassischer Dating Portale ab. Diese Männer verstehen 
                es Frauen für sich und ihr Leben zu
                begeistern. Für ihr Date sind sie oftmals Türöffner in bisher
                verschlossene Welten, Mentor und spannendes Abenteuer zugleich.
              </p>

              <p style={{ paddingTop: "1em" }}>
                Für die Herren liegt der Reiz an der
                Leichtigkeit und Experimentierfreude der zumeist jüngeren
                Partnerin. Er genießt die weibliche Begleitung und das
                Zusammensein mit einer attraktiven Dame während er ihr die
                schönen Seiten des Lebens zeigt.
              </p>
            </Grid>
          </Grid>
        </div>

        {purpleCTAContainer}
        <div className={classNames(classes.container, classes.hasMarginTop10)}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} className={classes.hasTextCentered}>
              <h2 className={classes.h2}>Warum du getasugar lieben wirst</h2>
              <p
                className={classNames(
                  classes.isTeaser,
                  classes.isTeaserFullwidth
                )}
              >
                Entdecke mit unserer Community die angenehmen und inspirierenden
                Seiten des Lebens: Unsere Mitglieder suchen Dates für besondere
                Momente und Verbindungen
              </p>

              <img
                src={sneakSearchProfiles.src}
                alt="App"
                className={classes.contentImage}
              />

              <p>
                Deine Registrierung ist kostenfrei. Auch ohne kostenpflichtige
                VIP Mitgliedschaft kannst du ein ausführliches Dating Profil
                erstellen, andere Mitglieder durch virtuelle Küsse auf dich
                aufmerksam machen und Nachrichten beantworten. Möchtest du
                unsere Plattform anonym kennenlernen, ist dies durch freie
                Namenswahl möglich.
              </p>
            </Grid>
          </Grid>
        </div>
        <div className={classes.rowGrey}>
          <div
            className={classNames(classes.container, classes.hasMarginTop10)}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classNames(
                  classes.hasTextCentered,
                  classes.switchOrderMobilePos2
                )}
              >
                <img src={sneakExplore.src} alt="Männer" height="320" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classes.switchOrderMobilePos1}
              >
                <h3>Lifestyle Dates, die zu dir passen</h3>
                <p>
                  Stilvolle Gentlemen nutzen getasugar.de, um auf attraktive
                  Damen zu treffen. Entdecke spannende Profile in deiner Nähe.
                </p>
              </Grid>
            </Grid>
          </div>
        </div>

        <div>
          <div
            className={classNames(classes.container, classes.hasMarginTop10)}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classes.switchOrderMobilePos1}
              >
                <h3>Gemeinsame Erlebnisse, die verbinden</h3>

                <p>
                  Außergewöhnliche Events mit inspirierenden Persönlichkeiten
                  erleben. Unsere Event Funktion bringt euch zusammen.
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classNames(
                  classes.hasTextCentered,
                  classes.switchOrderMobilePos2
                )}
              >
                <img
                  src={sneakEvents.src}
                  alt="Männer treffen"
                  height="320"
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.rowGrey}>
          <div
            className={classNames(classes.container, classes.hasMarginTop10)}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classNames(
                  classes.hasTextCentered,
                  classes.switchOrderMobilePos2
                )}
              >
                <img
                  src={sneakMessenger.src}
                  alt="Männer kennenlernen"
                  height="320"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classes.switchOrderMobilePos1}
              >
                <h3>Entspanntes Kennenlernen</h3>

                <p>
                  Ein komfortabler Chat mit privatem Bild-Upload gibt dir die
                  Möglichkeit, andere Mitglieder besser kennenzulernen und
                  Treffen zu planen.
                </p>
              </Grid>
            </Grid>
          </div>
        </div>

        {purpleCTAContainer}

        <div className={classNames(classes.container, classes.hasMarginTop10)}>
          <TeamSection />
        </div>

        {purpleCTAContainer}

        <div className={classNames(classes.container, classes.hasMarginTop10)}>
          <Grid container alignItems="stretch">
            <Grid item xs={12} className={classes.hasTextCentered}>
              <h2 className={classes.h2}>Dating in deiner Region</h2>
              <p
                className={classNames(
                  classes.isTeaser,
                  classes.isTeaserFullwidth
                )}
              >
                In vielen Städten in Deutschland, Österreich und der Schweiz
                warten aufregende Dates auf dich
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              className={classNames(
                classes.hasTextCentered,
                classes.hasMarginTop10
              )}
            >
              {tagCities.map((city) => (
                <Chip
                  label={city}
                  color="primary"
                  className={classes.primaryChip}
                />
              ))}
            </Grid>
          </Grid>
        </div>
      </div>

      <Footer />
    </div>
  );
}
