import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { Avatar } from "@material-ui/core";
import styles from "assets/jss/nextjs-material-kit/pages/landingPageSections/teamStyle.js";
import customStyles from "assets/jss/custom/customStyles.js";

import katjaImage from "assets/img/testimonials/sugardating-erfahrungen.jpg";
import robertImage from "assets/img/testimonials/sugardaddy-erfahrungen.jpg";
import marieImage from "assets/img/testimonials/sugarbabe-erfahrungen.jpg";

import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
});

export default function TeamSection() {
  const classes = useStyles();

  const testimonials = [
    {
      name: "Marie K.",
      age: "24",
      location: "München",
      image: marieImage.src,
      alt: "Erfahrungen einer Frau",
      quote:
        "Ich war zuerst skeptisch als mir eine Freundin von getasugar.de erzählt hat. Dennoch hat mich der Gedanke gereizt, einen Mann kennenzulernen, der auf große Erfolge im Leben zurückblicken kann. Hier habe ich jemanden gefunden, der für mich Abenteuer und Mentor zugleich ist – ganz ohne weitere Verpflichtungen.",
    },
    {
      name: "Robert M.",
      age: "38",
      location: "Klagenfurt",
      image: robertImage.src,
      alt: "Erfahrungen eines Mannes",
      quote:
        "Als viel beschäftigter Unternehmer fehlt mir die Zeit für komplizierte Beziehungen. Ich möchte aber trotzdem mit einer interessanten Frau reisen, ausgehen und die schönen Momente des Lebens mit ihr teilen. Auf getasugar.de finde ich Frauen, die identische Vorstellungen wie ich haben.",
    },
    {
      name: "Katja S.",
      age: "32",
      location: "Berlin",
      image: katjaImage.src,
      alt: "Dating Erfahrungen",
      quote:
        "Ich liebe es, wenn mir jeder Wunsch von den Augen abgelesen wird. Auf getasugar.de gibt es glücklicherweise Gentlemen, die meinen Ansprüchen gerecht werden. Meine bisherigen Dates wertschätzen die Impulse, die ich in ihr Leben bringe. So kann ich auf viele besondere Momente zurückblicken, die sicher nicht jeder so in seinem Leben einmal erlebt.",
    },
  ];

  return (
    <GridContainer alignItems="flex-start">
      <GridItem xs={12} className={classes.hasTextCentered}>
        <h2 className={classes.h2}>Erfahrungen unserer zufriedenen Nutzer</h2>
        <p className={classes.isTeaser}>
          Zahlreiche Mitglieder sind schon überzeugt von getasugar.de
        </p>
      </GridItem>

      <GridItem xs={12}>
        <Swiper
          slidesPerView="1"
          breakpoints={{
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, A11y]}
          pagination={{ clickable: true }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide>
              <Card
                plain
                style={{ padding: 10, paddingTop: 0, paddingBottom: 0 }}
              >
                <CardHeader image style={{ textAlign: "center" }}>
                  <Avatar
                    src={testimonial.image}
                    alt={testimonial.name}
                    style={{
                      width: "9em",
                      height: "9em",
                      display: "inline-block",
                    }}
                  />
                </CardHeader>
                <CardBody style={{ padding: "0" }}>
                  <h3
                    className={classNames(
                      classes.isBold,
                      classes.hasTextCentered
                    )}
                  >
                    {testimonial.name}
                  </h3>
                  <h6
                    className={classNames(
                      classes.isGrey,
                      classes.hasTextCentered
                    )}
                  >
                    {testimonial.age} Jahre, aus {testimonial.location}
                  </h6>

                  <p className={classes.hasTextCentered}>
                    "{testimonial.quote}"
                  </p>
                </CardBody>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </GridItem>

      <GridItem xs={12} style={{ marginBottom: "1em", marginTop: "2em" }}>
        <p
          className={classNames(
            classes.hasTextCentered,
            classes.isSmall,
            classes.isGrey
          )}
        >
          Eure Privatsphäre ist uns wichtig! Deshalb findest du an dieser Stelle
          ausschließlich Beispielfotos und Pseudonyme.
        </p>
      </GridItem>
    </GridContainer>
  );
}
