/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// @material-ui/icons
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// core components
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/nextjs-material-kit/components/headerLinksStyle.js";
import { successColor } from "assets/jss/nextjs-material-kit";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [belowFold, setBelowFold] = useState(false);

  const handleScroll = () => {
    const height = window.innerHeight;
    const position = window.pageYOffset;

    position >= height / 3 ? setBelowFold(true) : setBelowFold(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const theme = useTheme();
  const aboveSm = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const router = useRouter();

  const { adaptiveFold = false } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ gap: "1em", fontSize: "0.9em" }}
    >
      <Button
        className={classes.navLink}
        onClick={() => {
          router.push("/register");
        }}
        style={{
          backgroundColor: successColor,
          transition: "opacity 0.5s linear",
          opacity: belowFold || !adaptiveFold ? 1 : 0,
          overflow: "hidden",
        }}
      >
        {aboveSm ? "Kostenlos registrieren" : "Registrieren"}
      </Button>

      {aboveSm && !belowFold && adaptiveFold && (
        <Button
          href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
          color="transparent"
          className={classes.navLink}
        >
          Login
        </Button>
      )}

      {(!aboveSm || belowFold || !adaptiveFold) && (
        <Button
          href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
          color="transparent"
          className={classes.navLink}
        >
          <ExitToAppIcon style={{ marginRight: "0" }} />
        </Button>
      )}
    </Box>
  );
}
