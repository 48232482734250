const parallaxStyle = (theme) => ({
  parallax: {
    height: '580px',
    position: 'relative',
    backgroundPosition: 'right 70px',
    backgroundSize: 'cover',
    margin: '0',
    padding: '0',
    border: '0',
    display: 'flex',
    alignItems: 'center',
  },
  filter: {
    '&:before': {
      //background: "rgba(0, 0, 0, 0.5)",
    },
    '&:after,&:before': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: "''",
    },
  },
  small: {
    height: '380px',
  },

  parallaxResponsive: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'rgba(255,255,255,1)',
      backgroundPosition: 'center 60px',
      minHeight: '320px',
      height: 'auto',
      backgroundRepeat: 'no-repeat',
      //backgroundSize: '200%',
      backgroundSize: 'cover',
      paddingTop: '68vw',
      paddingBottom: '0',
      transform: 'none !important',
    },
  },
})

export default parallaxStyle
