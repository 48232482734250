import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import LockIcon from "@material-ui/icons/Lock";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

//styles

import styles from "assets/jss/nextjs-material-kit/components/badgeStyle.js";
import customStyles from "assets/jss/custom/customStyles.js";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
});

export default function USPBlock() {
  const classes = useStyles();

  const iconStyle = { fontSize: 60 };
  const iconClasses = classNames(classes.hasTextShadow, classes.hasPadding);

  const trendingIcon = (
    <TrendingUpIcon
      style={iconStyle}
      className={iconClasses}
      alt="Community"
    />
  );

  const userIcon = (
    <VerifiedUserIcon
      style={iconStyle}
      className={iconClasses}
      alt="Plattform"
    />
  );

  const lockIcon = (
    <LockIcon
      style={iconStyle}
      className={iconClasses}
      alt="Seriöse Dating Plattform"
    />
  );

  const usps = [
    {
      headline: "Wachsende Community",
      subline: "Viele Registrierungen täglich und viele aktive Mitglieder",
      icon: trendingIcon,
    },
    {
      headline: "Kompetent & Qualifiziert",
      subline: "Die wohl beste Plattform für attraktive Frauen & erfolgreiche Männer",
      icon: userIcon,
    },
    {
      headline: "Grösstmögliche Sicherheit",
      subline: "Serverstandort und Datenschutz in Deutschland",
      icon: lockIcon,
    },
  ];

  return (
    <div className={classes.rowOdd}>
      <div className={classes.container}>
        <GridContainer
          alignItems="stretch"
          justify="space-between"
          direction="row"
        >
          {usps.map((usp) => (
            <GridItem xs={12} sm={4}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>{usp.icon}</GridItem>
                <GridItem xs={9}>
                  <h6 className={classNames(classes.isOverline)}>
                    {usp.headline}
                  </h6>
                  <h3 className={classNames(classes.isNarrow, classes.isBold)}>
                    {usp.subline}
                  </h3>
                </GridItem>
              </GridContainer>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
